import React, { FunctionComponent } from 'react';
import {Route, Routes} from "react-router-dom";
import {MainPage} from "../pages/MainPage/MainPage";
import {routesPath} from "./const";
import InstructionsPage from "../pages/InstructuinsPage/InstructionsPage";
import Devices from "../pages/DevicesPage/Devices";
import SudirAuthPage from "../pages/SudirAuthPage/SudirAuthPage";
import NotFoundPage from "../pages/NotFoundPage/NotFoundPage";
import Error503Page from "../pages/Error503Page/Error503Page";
import SudirErrorPage from "../pages/SudirErrorPage/SudirErrorPage";
import QrCodeDownload from "../pages/QrCodeDownload/QrCodeDownload";

interface OwnProps {}

type Props = OwnProps;

const RoutesApp: FunctionComponent<Props> = (props) => {

  return (
      <Routes>
          <Route path={routesPath.main} element={<MainPage/>}/>
          <Route path={routesPath.devices} element={<Devices/>}/>
          <Route path={routesPath.instructions} element={<InstructionsPage/>}/>
          <Route path={routesPath.sudirAuth} element={<SudirAuthPage/>}/>
          <Route path={routesPath.serverError} element={<Error503Page/>}/>
          <Route path={routesPath.sudirBlocked} element={<SudirErrorPage/>}/>
          <Route path={routesPath.qrCodeDownload} element={<QrCodeDownload/>}/>
          <Route path={"*"} element={<NotFoundPage/>}/>
      </Routes>
  );
};

export default RoutesApp;
