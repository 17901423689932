import React, {FunctionComponent} from 'react';
import style from "./SomethingWrongPage.module.scss";
import {Button, Typography} from "@sber-friend/flamingo-core";
import SomethingWrongImg from "../../../assets/img/Devices/SomethingWrong.svg"


interface OwnProps {
    error: number
}

type Props = OwnProps;

const SomethingWrongPage: FunctionComponent<Props> = ({error}) => {

    const reload = () => {
        window?.location?.reload()
    }


    return (
        <div className={style.wrap}>
            <div className={style.content}>
                <img src={SomethingWrongImg} alt="SomethingWrong"/>
                <div>
                    <Typography variant={"h5"}>Что-то пошло не так</Typography>
                    <Typography variant={"body1"} color={"textSecondary"}>
                        Свяжитесь с поддержкой:<br/>
                        Сервис «<a
                        href="https://sberfriend.sbrf.ru/sberfriend/#/application/97C2E1A8DE5B93ACE054022128574717"
                        className={"green-link"}>Планшеты, смартфоны и ТСД</a>» в Друге<br/>
                        <a href="tel:855711000" className={"green-link"}>8-557-11000</a> – внутренний номер<br/>
                        <a href="tel:+74957000000" className={"green-link"}>+7 495 700-00-00</a> – внешний номер
                    </Typography>
                    <Typography variant={"caption2"} color={"textSecondary"}>Код ошибки: {error}</Typography>
                    <Button color={"primary"} onClick={reload}>Перезагрузить</Button>
                </div>
            </div>
        </div>
    );
};

export default SomethingWrongPage;
