import React, {FunctionComponent, useState} from 'react';
import {Button, Dialog, Typography} from "@sber-friend/flamingo-core";
import style from "./ConfirmModal.module.scss"

interface OwnProps {
    openModal: boolean,
    deleteDeviceName?: Array<string>
    onConfirm: () => void
    onCloseModal: () => void
}

type Props = OwnProps;

const ConfirmModal: FunctionComponent<Props> = ({openModal, onCloseModal, onConfirm, deleteDeviceName}) => {

    const [loadingConfirmBtn, setLoadingConfirmBtn] = useState(false);

    const handleDelete = async () => {
        setLoadingConfirmBtn(true)
        await onConfirm()
        setLoadingConfirmBtn(false)
        onCloseModal()
    }

    return (
        <Dialog open={openModal} onClose={onCloseModal}>
            <div className={style.wrap}>
                <Typography variant={"h5"}>
                    {
                        !deleteDeviceName?.length ? "Удалить все устройства?"
                            : deleteDeviceName.length === 1 ? `Удалить устройство ${deleteDeviceName[0]}?`
                                : deleteDeviceName?.length < 5 ? `Удалить ${deleteDeviceName.length} устройства?` : `Удалить ${deleteDeviceName.length} устройств?`
                    }
                </Typography>
                <Typography className={style.text}>
                    {
                        !deleteDeviceName?.length ?
                            "После удаления всех устройств, вы сможете снова получить доступ к OTP, добавив нужное устройство на портале."
                            : deleteDeviceName.length === 1 ?
                                "После удаления устройства, вы сможете снова получить на нём доступ к OTP, добавив его повторно на портале."
                                : "Чтобы получать доступ к OTP на данных устройствах, пользователю будет необходимо зарегистрировать их заново."
                    }

                </Typography>
                <div className={style.btnWrap}>
                    <Button onClick={onCloseModal}>Отмена</Button>
                    <Button color={"primary"} onClick={handleDelete} loading={loadingConfirmBtn}>Удалить</Button>
                </div>
            </div>

        </Dialog>
    );
};

export default ConfirmModal;
