import React, {FunctionComponent, useState} from 'react';
import style from "./SideBar.module.scss";
import {
    Button,
    Dialog,
    Typography
} from "@sber-friend/flamingo-core";

import {useWindowSize} from "usehooks-ts";
import {SimpleArrowDownIcon, SimpleArrowLeftIcon} from "@sber-friend/flamingo-icons";

interface OwnProps {
    listItems: Array<string>;
    activeMenuStep: number;
    onClickMenuItem: (i: number) => void;
}


type Props = OwnProps;

const SideBar: FunctionComponent<Props> = ({listItems, activeMenuStep, onClickMenuItem}) => {


    const {width} = useWindowSize();
    const [open, setOpen] = useState(false);

    const handleCloseModal = () => {
        setOpen(false)
    }


    const handleClickMenuItem = (i: number) => () => {
        if (open) {
            setOpen(false)
        }
        onClickMenuItem(i)
    }
    const handleClickMobileMenuTitle = () => {
        setOpen(true)
    }

    return (
        <>
            <div className={style.stepsMenu}>
                {
                    width > 850 ? listItems.map((e, i) => <Typography variant={"body2"}
                                                                      className={style.stepsMenuItem + (i === activeMenuStep ? " " + style.stepsMenuItemActive : "")}
                                                                      color={"textSecondary"}
                                                                      onClick={handleClickMenuItem(i)}
                                                                      key={`stepsMenuItem ${i}`}
                        >
                            {e}
                        </Typography>) :
                        <Typography variant={"h5"} className={style.mobileMenuTitle}
                                    onClick={handleClickMobileMenuTitle}>
                            {listItems.find((e, i) => i === activeMenuStep)}
                            <SimpleArrowDownIcon/>
                        </Typography>
                }
            </div>
            <Dialog fullScreen open={open}>
                <div className="container">
                    <div className={style.modalTitle}>
                        <Button startIcon={<SimpleArrowLeftIcon/>} fullWidth variant={"text"}
                                onClick={handleCloseModal}>
                            <Typography variant={"h6"}>Как установить приложение</Typography>
                        </Button>
                        <div className={style.list}>
                            {
                                listItems.map((e, i) => <Typography
                                    key={`listItem${i}`}
                                    variant={activeMenuStep === i ? "subtitle2" : "body2"}
                                    className={activeMenuStep === i ? style.activeListItem : ""}
                                    onClick={handleClickMenuItem(i)}
                                >
                                    {e}
                                </Typography>)
                            }
                        </div>
                    </div>
                </div>
            </Dialog>
        </>
    );
};

export default SideBar;
