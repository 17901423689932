import React, {FunctionComponent, useRef, useState} from 'react';
import style from "./InstructionsPage.module.scss"
import {Button, Dialog, Tab, Tabs, Typography} from "@sber-friend/flamingo-core";
import InstructionTab, {InstructionTabHandles} from "./InstructionTab/InstructionTab";
import {AndroidContent, IOSContent} from "./Content";
import SideBar from "./SideBar/SideBar";
import {useWindowSize} from "usehooks-ts";
import {AndroidIcon, ExternalLinkIcon, IosIcon} from "@sber-friend/flamingo-icons";

interface OwnProps {
}

type Props = OwnProps;

const InstructionsPage: FunctionComponent<Props> = (props) => {


    const {width} = useWindowSize();

    const iOSTabHandles = useRef<InstructionTabHandles>(null);
    const androidTabHandles = useRef<InstructionTabHandles>(null);

    const [openModal, setOpenModal] = useState(true)
    const [activeTab, setActiveTab] = useState(0)
    const [activeIOSMenuStep, setActiveIOSMenuStep] = useState(0)
    const [activeAndroidMenuStep, setActiveAndroidMenuStep] = useState(0)

    const handleCloseModal = () => {
        setOpenModal(false)
    }

    const handleModalActionsClick = (i: number) => () => {
        setActiveTab(i)
        handleCloseModal()
    }

    const handleChangeTab = (event: Object, newValue: number) => {
        setActiveTab(newValue);
    }

    const handleChangeIOSActiveMenuStep = (index: number) => {

        setActiveIOSMenuStep(index)
    }

    const handleClickIOSMenuItem = (i: number) => {
        if (width > 850) {
            iOSTabHandles?.current?.changeActiveItem(IOSContent[i]?.children?.length && !IOSContent[i].img ? 0 : undefined);
        } else {
            iOSTabHandles?.current?.changeActiveItem(0);
        }
        handleChangeIOSActiveMenuStep(i)
    }
    const handleChangeAndroidActiveMenuStep = (index: number) => {
        setActiveAndroidMenuStep(index)
    }

    const handleClickAndroidMenuItem = (i: number) => {
        if (width > 850) {
            androidTabHandles?.current?.changeActiveItem(AndroidContent[i]?.children?.length && !AndroidContent[i].img ? 0 : undefined);
        } else {
            androidTabHandles?.current?.changeActiveItem(0);
        }
        handleChangeAndroidActiveMenuStep(i)
    }

    const getLinkToInstructionPdf = ():string => {
        if (activeTab === 0){
            return "https://otp.sberbank.ru/instructions/iOs_instruction.pdf"
        }
        return "https://otp.sberbank.ru/instructions/Android_instruction.pdf"
    }

    return (
        <div className={style.wrap}>
            <Typography variant={width > 600 ? "h2" : "h5"} className={style.title}>
                Как установить приложение
            </Typography>
            <div className={style.tabsWrap}>
                <Tabs
                    value={activeTab}
                    onChange={handleChangeTab}
                >
                    <Tab
                        label={"iOS"}
                    />
                    <Tab
                        label={"Android"}
                    />
                </Tabs>
                <a href={getLinkToInstructionPdf()} rel="noreferrer"  target={"_blank"} className={style.instructionLink}>Инструкция в PDF <ExternalLinkIcon
                    fontSize={"small"}/></a>
            </div>

            <div className={style.content}>
                {
                    activeTab === 0 ? <>
                            <SideBar listItems={IOSContent.map(el => el.menuLabel)} activeMenuStep={activeIOSMenuStep}
                                     onClickMenuItem={handleClickIOSMenuItem}/>
                            <div className={style.tabContent}>
                                <InstructionTab activeMenuStep={activeIOSMenuStep}
                                                handleChangeActiveMenuStep={handleChangeIOSActiveMenuStep}
                                                ref={iOSTabHandles}
                                                content={IOSContent}
                                                tab={"ios"}
                                />
                            </div>
                        </>
                        : <>
                            <SideBar listItems={AndroidContent.map(el => el.menuLabel)}
                                     activeMenuStep={activeAndroidMenuStep}
                                     onClickMenuItem={handleClickAndroidMenuItem}/>
                            <div className={style.tabContent}>
                                <InstructionTab activeMenuStep={activeAndroidMenuStep}
                                                handleChangeActiveMenuStep={handleChangeAndroidActiveMenuStep}
                                                ref={androidTabHandles}
                                                content={AndroidContent}
                                                tab={"android"}
                                />
                            </div>
                        </>
                }

            </div>
            <Dialog open={openModal}>
                <div className={style.modalContent}>
                    <Typography variant={"h6"}>Выберите мобильное устройство</Typography>
                    <div className={style.modalActions}>
                        <Button variant="contained" startIcon={<IosIcon/>} onClick={handleModalActionsClick(0)}>
                            iOS
                        </Button>
                        <Button variant="contained" startIcon={<AndroidIcon/>} onClick={handleModalActionsClick(1)}>
                            Android
                        </Button>
                    </div>
                </div>
            </Dialog>
        </div>
    );
};

export default InstructionsPage;
