import React, {
    forwardRef,
    ForwardRefRenderFunction, useEffect,
    useImperativeHandle, useRef,
    useState
} from 'react';
import style from "./InstructionTab.module.scss";
import {Button, Carousel, Typography} from "@sber-friend/flamingo-core";
import {useNavigate} from "react-router-dom";
import {routesPath} from "../../../routes/const";
import {TabContentType} from "../Content";
import {useEffectOnce, useWindowSize} from "usehooks-ts";
import {useLazyGetMobileVersionQuery} from "../../../api/main";
import {sendEvent} from '@sbol/clickstream-agent/sdk';
import {useCountdown} from "../../../hooks/common";
import {getCookie, setCookie} from "../../../helpers";
import Snackbar from "@sber-friend/flamingo-core/Snackbar";

interface OwnProps {
    activeMenuStep: number;
    handleChangeActiveMenuStep: (i: number) => void;
    content: Array<TabContentType>;
    tab: "ios" | "android";
}

export interface InstructionTabHandles {
    changeActiveItem: (i?: number | undefined) => void;
};

type Props = OwnProps;


const InstructionTab: ForwardRefRenderFunction<InstructionTabHandles, Props> = ({
                                                                                    activeMenuStep,
                                                                                    handleChangeActiveMenuStep,
                                                                                    content,
                                                                                    tab
                                                                                }, ref) => {

    const COUNTDOWN_TIMER = 15;

    const [getMobileVersion, {data: mobileVersion}] = useLazyGetMobileVersionQuery();

    const {width} = useWindowSize();
    const navigate = useNavigate();
    const activeMenuStepContent = content[activeMenuStep];

    const slider = useRef<any>(null);
    const autoPlay = useRef<any>(null);


    const [activeItem, setActiveItem] = useState<undefined | number>(0)
    const [initSlider, setInitSlider] = useState(false)

    const {seconds, startCountdown} = useCountdown(0);
    const [showDownloadAlert, setShowDownloadAlert] = useState("");

    const handleClickItem = (i: number) => () => {
        if (slider.current) {
            slider.current?.slickGoTo(i)
        }
        setActiveItem(i)
        if (autoPlay.current) {
            clearInterval(autoPlay.current)
        }

    }

    const handleClickPrevStepButton = () => {
        let prevChild = content[(activeMenuStep - 1)]
        setActiveItem(() => prevChild.img ? undefined : 0)
        handleChangeActiveMenuStep(activeMenuStep - 1)
    }

    const handleClickNextStepButton = () => {
        setActiveItem(undefined)
        handleChangeActiveMenuStep(activeMenuStep + 1)
    }

    const handleClickMobilePrevStepButton = () => {
        const nextChildEL = activeMenuStepContent.children ? activeMenuStepContent?.children[activeItem ? (activeItem - 1) : -1] : undefined;
        let prevChild = content[(activeMenuStep - 1)]
        if (nextChildEL) {
            setActiveItem(prevState => prevState ? prevState - 1 : undefined)
        } else {
            if (prevChild.children) {
                setActiveItem(prevChild.children?.length - 1)
            }
            handleChangeActiveMenuStep(activeMenuStep - 1)
        }

    }

    const handleClickMobileNextStepButton = () => {
        const nextChildEL = activeMenuStepContent.children ? activeMenuStepContent?.children[activeItem ? (activeItem + 1) : 0] : undefined;
        if (nextChildEL) {
            setActiveItem(prevState => prevState ? prevState + 1 : 1)
        } else {
            setActiveItem(0)
            handleChangeActiveMenuStep(activeMenuStep + 1)
        }
    }

    const handleClickRegistrationButton = () => {
        navigate(routesPath.devices)
    }

    const handlerDownload = (mob: string) => () => {

        setCookie("startedDownload", Date.now().toString())
        startCountdown(COUNTDOWN_TIMER)

        if (mob === "ios") {
            setShowDownloadAlert("Началась установка приложения. Приложение ОТР отобразится на главном экране вашего устройства")
            window.open(window.location.origin + "/store/ios", "_blank")
            // отправка метрик click stream
            sendEvent(
                {
                    eventCategory: 'Скачать приложение',
                    eventAction: 'Страница инструкций',
                    value: 'iosDownload'
                }
            )
            //
        } else {
            setShowDownloadAlert("Началось скачивание приложения. Дистрибутив находится в проводнике файлов")
            window.open(window.location.origin + "/store/android", "_blank")
            // отправка метрик click stream
            sendEvent(
                {
                    eventCategory: 'Скачать приложение',
                    eventAction: 'Страница инструкций',
                    value: 'androidDownload'
                }
            )
            //
        }

    }

    const MobImg = () => {
        let mImg = null;
        if ((activeItem !== undefined) && activeMenuStepContent?.children?.length && (activeMenuStepContent.children.length > activeItem)) {
            mImg = <img
                className={activeMenuStepContent.children[activeItem].widePicture ? style.widePicture : ""}
                src={activeMenuStepContent.children[activeItem].mobImg ? activeMenuStepContent.children[activeItem].mobImg : activeMenuStepContent.children[activeItem].img}
                alt="Img"/>

        } else {
            mImg = <img
                className={activeMenuStepContent.widePicture ? style.widePicture : ""}
                src={activeMenuStepContent.mobImg ? activeMenuStepContent.mobImg : activeMenuStepContent.img}
                alt="Img"/>
        }
        return mImg
    }

    const Img = () => {
        let frontImg = null;
        if ((activeItem !== undefined) && activeMenuStepContent?.children?.length && (activeMenuStepContent.children.length > activeItem) && !activeMenuStepContent.bgImg) {
            frontImg = <img
                className={activeMenuStepContent.children[activeItem].widePicture ? style.widePicture : ""}
                src={activeMenuStepContent.children[activeItem].img}
                alt="Img"/>

        } else if (activeMenuStepContent.img && (activeItem === undefined)) {
            frontImg = <img
                className={activeMenuStepContent.widePicture ? style.widePicture : ""}
                src={activeMenuStepContent.img}
                alt="Img"/>
        }
        return <>
            {frontImg}
            {
                activeMenuStepContent?.qr && activeMenuStepContent.qr
            }
            {activeMenuStepContent.bgImg && (activeItem !== undefined) && <img
                className={style.bgImg}
                src={activeMenuStepContent.bgImg}
                alt="bgImg"/>}
        </>
    }

    useImperativeHandle(ref, () => ({
        changeActiveItem: (i = undefined) => {
            setActiveItem(i)
        }
    }));


    useEffect(() => {
        if (!activeMenuStepContent.img && width > 850) {
            setActiveItem(0)
        }


        if (activeMenuStepContent.children?.length && activeMenuStepContent.bgImg) {
            // if (initSlider) {
            //     // stop autoplay
            //     let sliderWrap = document.querySelector(".slick-slider")
            //     if (sliderWrap) {
            //         sliderWrap.addEventListener("mousedown", () => {
            //             clearInterval(autoPlay.current)
            //         })
            //     }
            // }
            //
            // // autoplay slider
            // autoPlay.current = setInterval(() => {
            //     setActiveItem(prevState => {
            //         if ((prevState !== undefined) &&
            //             activeMenuStepContent.children?.length &&
            //             (activeMenuStepContent.children?.length > prevState + 1)) {
            //             slider.current?.slickGoTo(prevState + 1)
            //             return prevState + 1
            //         } else {
            //             slider.current?.slickGoTo(0)
            //             return 0
            //         }
            //     })
            // }, 2500)
        }
        return () => {
            // if (autoPlay.current) {
            //     clearInterval(autoPlay.current)
            // }
        }
    }, [activeMenuStepContent, initSlider, width])

    useEffectOnce(() => {

        const startedDownload = getCookie("startedDownload")
        if (Number(startedDownload)) {
            const countdownSecondLeft = COUNTDOWN_TIMER - Math.round((Number(Date.now()) - Number(startedDownload)) / 1000)
            if (countdownSecondLeft > 0) {
                startCountdown(countdownSecondLeft)
            } else {
                setCookie("startedDownload", 0)
            }
        }

        if (width <= 850) {
            getMobileVersion(undefined)
        }
    })


    // mobile view
    if (width <= 850) {

        return <div className={style.wrap}>
            <Typography variant={"body1"} className={style.subTitle}>
                {activeMenuStep === 1 ? "Нажмите кнопку ниже и скачайте приложение." : activeMenuStepContent.subTitle}
            </Typography>
            {
                activeMenuStep === 1 && (
                    tab === "ios" ? <Button color={"primary"} style={{marginTop: 16}}
                                            disabled={!!seconds}
                                            onClick={handlerDownload("ios")}
                        >
                            {seconds ? `Скачать (${seconds})`
                                : "Скачать приложение " + mobileVersion?.result.iosVersion}
                        </Button>
                        : <Button color={"primary"} style={{marginTop: 16}}
                                  onClick={handlerDownload("android")}
                                  disabled={!!seconds}
                        >
                            {seconds ? `Скачать (${seconds})`
                                : "Скачать приложение " + mobileVersion?.result.androidVersion}
                        </Button>
                )
            }
            <div className={style.mobileImg}>
                <MobImg/>
            </div>
            {
                activeMenuStepContent.children?.length && <div className={style.mobileChildItem}>
                    <span>{activeItem ? (activeItem + 1) : 1}</span>
                    <Typography variant={"body1"}>
                        {activeMenuStepContent.children[activeItem ? activeItem : 0].title}
                    </Typography>
                </div>
            }
            <div className={style.buttonsWrap}>
                {
                    (activeItem && (activeItem > 0)) || activeMenuStep > 0 ? <Button className={style.prevStepButton}
                                                                                     onClick={handleClickMobilePrevStepButton}
                    >
                        Назад
                    </Button> : null
                }
                {
                    (activeMenuStep + 1) === content.length ?
                        <Button color={"primary"} className={style.nextStepButton}
                                onClick={handleClickRegistrationButton}>
                            Зарегистрировать
                        </Button>
                        : <Button color={"primary"} className={style.nextStepButton}
                                  onClick={handleClickMobileNextStepButton}>
                            Дальше
                        </Button>
                }

            </div>
        </div>
    }

    // desktop view
    return (
        <div className={style.wrap}>
            {(activeMenuStepContent !== undefined) && <>
                <div className={style.text}>
                    <Typography variant={"h5"} className={style.title}>
                        {activeMenuStepContent.title}
                    </Typography>
                    <Typography variant={"body1"} className={style.subTitle}>
                        {activeMenuStepContent.subTitle}
                    </Typography>
                    <div className={style.actionsWrap}>
                        {
                            activeMenuStepContent?.children?.map((child, index) =>
                                <div
                                    className={style.actionsItem + (activeItem === index ? ` ${style.activeItem}` : "")}
                                    onClick={handleClickItem(index)}
                                    key={`${index} actionsItem`}
                                >
                                    <span>{index + 1}</span>
                                    <div className={style.itemTextWrap}>
                                        <Typography
                                            variant={activeItem === index ? "h6" : "body1"}>
                                            {child.title}
                                        </Typography>
                                        {child.subTitle && (activeItem === index) &&
                                            <Typography variant={"subtitle2"} className={style.itemSubTitle}>
                                                {child.subTitle}
                                            </Typography>}
                                    </div>

                                </div>)
                        }
                    </div>
                    <div className={style.buttonsWrap}>
                        {
                            activeMenuStep > 0 && <Button className={style.prevStepButton}
                                                          onClick={handleClickPrevStepButton}
                            >
                                Назад
                            </Button>
                        }
                        {
                            (activeMenuStep + 1) === content.length ?
                                <Button color={"primary"} className={style.nextStepButton}
                                        onClick={handleClickRegistrationButton}>
                                    Зарегистрировать
                                </Button>
                                : <Button color={"primary"} className={style.nextStepButton}
                                          onClick={handleClickNextStepButton}>
                                    Дальше
                                </Button>
                        }

                    </div>
                </div>
                <div className={style.imageWrap}>
                    <Img/>
                    {
                        activeMenuStepContent.bgImg && <Carousel
                            ref={slider}
                            dots={false}
                            slidesToScroll={1}
                            arrows={false}
                            beforeChange={(old: number, newI: number) => {
                                setActiveItem(newI)
                            }}
                            className={style.slider + (activeItem === undefined ? ` ${style.hideSlider}` : "")}
                            onInit={() => {
                                setInitSlider(true)
                            }}
                        >
                            {activeMenuStepContent?.children?.map((el, i) => <img
                                key={`caruselImg${i}`}
                                src={el.img}
                                alt={el?.title?.toString()}/>)}
                        </Carousel>
                    }
                </div>
            </>}
            <Snackbar
                placement={"top"}
                open={!!showDownloadAlert}
                autoHideDuration={10000}
                transitionDuration={300}
                TransitionProps={{}}
                onClose={(e,reason) => {
                    if (reason === 'clickaway') {
                        return;
                    }
                    setShowDownloadAlert("")
                }}
                message={showDownloadAlert}
            />
        </div>
    );
};

export default forwardRef(InstructionTab);
