import jsrsasign from "jsrsasign";

const storageKey = window.btoa("devices");
const arg = "MIGfMA0GCSqGSI";
const rsaPublicKey = `-----BEGIN PUBLIC KEY-----
MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCVJHnM6AgVZMCMSwWfFF+Uquhd
s80uA/AZzSAJIn+4O3T7yShy6BXzfbFBrYxZLaMWEwzJ1nAGpG8c4bh31OLPtJ4W
+t4ouXOBZmTNNDYMIfLR9zoQ9r0hdvuU9xzFH8D9q5esmD5Co450D2EUBw74Y1wM
FJDlclAfyP7iVTZ+awIDAQAB
-----END PUBLIC KEY-----`;


export async function generateToken(pilot: boolean) {
    // Header
    const oHeader = {alg: "HS256", typ: "JWT"};
    // Payload
    const oPayload: Record<string, any> = {};
    // const tNow = KJUR.jws.IntDate.get("now");
    const tNow = Date.now();
    // const tEnd = KJUR.jws.IntDate.get("now + 1hour");
    const tEnd = new Date(tNow + 5 * 60 * 1000);
    oPayload.iss = "https://otp.sberbank.ru/";
    oPayload.sub = "mailto:support_store@sberbank.ru";
    oPayload.nbf = tNow;
    oPayload.iat = tNow;
    oPayload.exp = tEnd;
    oPayload.jti = "id123456";
    oPayload.aud = "https://otp.sberbank.ru/devices";
    oPayload.pilot = pilot;
    const sHeader = JSON.stringify(oHeader);
    const sPayload = JSON.stringify(oPayload);
    const sJWT = jsrsasign.KJUR.jws.JWS.sign("HS256", sHeader, sPayload, arg);
    return sJWT;
}


export function getKJURPublicKey(login: string) {
    // const rsaKeypair = KJUR.KEYUTIL.generateKeypair("RSA", 512);
    // console.log(
    //   "🚀 ~ file: jwt.ts ~ line 46 ~ getKJURPublicKey ~ rsaKeypair",
    //   rsaKeypair
    // );
    return rsaPublicKey;
}


export function parseJwt(token: string) {
    try {
        const base64Payload = token.split(".")[1];
        const payload = window.atob(base64Payload);
        return JSON.parse(payload);
    } catch (error: unknown) {
        console.log("🚀 ~ parseJwt ~ error", error);
        return null;
    }
}

export function checkRottenToken(token: string) {
    const payload = parseJwt(token);
    if (payload) {
        const expDate = payload.exp * 1000;
        const isTokenRotten = expDate < Date.now();
        return isTokenRotten;
    }
    return true;
}

export async function setSessionStorage(token: string) {
    // const value = btoa(token);
    const value = await encryptToken(token);
    sessionStorage.setItem(storageKey, value);
}

export function clearSessionStorage() {
    sessionStorage.removeItem(storageKey);
    // sessionStorage.clear();
}


export async function getTokenFromSessionStorage(): Promise<string | null> {
    try {
        const data = sessionStorage?.getItem(storageKey);
        if (data) {
            const token = await decryptToken(data);
            if (typeof token === "string") {
                const isTokenRotten = checkRottenToken(token);
                if (isTokenRotten) {
                    clearSessionStorage()
                    return null;
                }
                return token;
            }
        }
    } catch (e) {
        console.error("getTokenFromSessionStorage ~ error", e);
    }
    return null;
}

export function encryptPassword(key: string, pw: string) {
    return import(
        /* webpackChunkName: "lib/jsencrypt" */ "../assets/lib/jsencrypt.min"
        ).then(module => {
        const JSEncrypt = module.default;
        const encrypt = new JSEncrypt();
        encrypt.setPublicKey(key);
        const encryptedPw = encrypt.encrypt(pw);
        return encryptedPw;
    });
}

export function encryptToken(token: string) {
    return import(
        /* webpackChunkName: "lib/simplecrypt" */ "../assets/lib/simplecrypt.min"
        ).then(module => {
        const SimpleCrypto = module.default;
        const simpleCrypto = new SimpleCrypto(arg)
        const encryptedToken: string = simpleCrypto.encrypt(token);
        return encryptedToken;
    });
}


export function decryptToken(token: string) {
    return import(
        /* webpackChunkName: "lib/simplecrypt" */ "../assets/lib/simplecrypt.min"
        ).then(module => {
        const SimpleCrypto = module.default;
        const simpleCrypto = new SimpleCrypto(arg)
        const decryptedToken: string = simpleCrypto.decrypt(token);
        return decryptedToken;
    });
}
