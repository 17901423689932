import {createSlice, PayloadAction} from '@reduxjs/toolkit'


type devicesState = {
    somethingWrongError: number
}

const SomethingWrongErrorArray = [1, 5, 44, 62, 65, 66, 71, 75, 77, 100, 101, 102]

const devicesSlice = createSlice({
    name: 'devices',
    initialState: {} as devicesState,
    reducers: {
        setError: (state, action: PayloadAction<number>) => {
            if (SomethingWrongErrorArray.includes(action.payload)) {
                state.somethingWrongError = action.payload;
            }

        },
    },
})

export const {setError} = devicesSlice.actions


export default devicesSlice.reducer
