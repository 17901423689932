import React, {FunctionComponent, useState} from 'react';
import MyDevices from "./MyDevices/MyDevices";
import {Tab, Tabs, Typography} from "@sber-friend/flamingo-core";
import style from "./DevicesPage.module.scss";
import Delegation from "./Delegation/Delegation";
import {useGetUserRoleQuery} from "../../api/devices";
import {useEffectOnce, useLocalStorage} from 'usehooks-ts';
import HardwareToken from './HardwareToken/HardwareToken';
import TokenProgramming from './TokenProgramming/TokenProgramming';
import {useSearchParams} from "react-router-dom";
import {setProfile} from "@sbol/clickstream-agent";
import {useAppSelector} from "../../hooks/redux";
import SomethingWrongPage from "./SomethingWrongPage/SomethingWrongPage";
import UnbindingToken from "./UnbindingToken/UnbindingToken";


interface OwnProps {
}

type Props = OwnProps;

export const DevicesPage: FunctionComponent<Props> = (props) => {

    const tabs = [
        <MyDevices/>,
        <HardwareToken/>,
        <TokenProgramming/>,
        <UnbindingToken/>,
        <Delegation/>,
    ]

    const somethingWrongError = useAppSelector(state => state.devices.somethingWrongError);

    const {data: userRole} = useGetUserRoleQuery(undefined);


    const [searchParams] = useSearchParams();


    const [activeTab, setTab] = useState(0)


    const [isEsiaReferrer] = useLocalStorage('esiaTokensReferrer', false);
    const [isSberIdReferrer] = useLocalStorage('sberIdTokensReferrer', false);


    const delegation = userRole?.result.available_sections.find(e => e === "vip_support");
    const hardwareToken = userRole?.result.available_sections.find(e => e === "hwotp_user");
    const tokenProgramming = userRole?.result.available_sections.find(e => e === "hwotp_admin");

    const handleChangeTab = (event: Object, newValue: number) => {
        const tabWrap = document.querySelector<HTMLElement>(`.${style.tabContent}`)

        if (newValue < activeTab) {
            tabWrap?.classList.remove(style.lft)
            tabWrap?.classList.remove(style.rtl)
            setTimeout(() => {
                tabWrap?.classList.add(style.lft)
            })
        } else if (newValue > activeTab) {
            tabWrap?.classList.remove(style.rtl)
            tabWrap?.classList.remove(style.lft)
            setTimeout(() => {
                tabWrap?.classList.add(style.rtl)
            })

        }
        setTab(newValue)
    }


    const onMount = async () => {
        if ((isEsiaReferrer || isSberIdReferrer) && hardwareToken && searchParams.toString()) {
            setTab(1)
        }
        // click stream
        const sigmaLogin = userRole?.result.user_login.split('@')[0]
        if (sigmaLogin) {
            setProfile({
                hashUserLoginId: sigmaLogin,
                appVersion: process.env.APP_VERSION,
            })
        }
    }

    useEffectOnce(() => {
        onMount()
    })

    return somethingWrongError ? <SomethingWrongPage error={somethingWrongError}/>
        : <>
            <div className={style.head}>
                {
                    delegation || hardwareToken || tokenProgramming ? (<Tabs
                        value={activeTab}
                        onChange={handleChangeTab}
                    >
                        <Tab label="Мобильные устройства" value={0}/>
                        {hardwareToken && <Tab label="Токен" value={1}/>}
                        {tokenProgramming && <Tab label="Программирование" value={2}/>}
                        {tokenProgramming && <Tab label="Отвязка" value={3}/>}
                        {delegation && <Tab label="Делегирование" value={4}/>}
                    </Tabs>) : <Typography variant={"h2"}>Устройства</Typography>
                }
            </div>

            {
                delegation || hardwareToken || tokenProgramming ? (
                        <div className={style.tabContent}>
                            {tabs[activeTab]}
                        </div>)
                    : <MyDevices/>
            }

        </>
};


