import React, {FunctionComponent, PropsWithoutRef, ReactNode} from 'react';
import style from "./SuccessAlert.module.scss";
import {Typography} from "@sber-friend/flamingo-core";
import { CheckIcon } from '@sber-friend/flamingo-icons';

interface OwnProps extends PropsWithoutRef<any> {
    children?: string | ReactNode
}

type Props = OwnProps;

const SuccessAlert: FunctionComponent<Props> = ({children, ...props}) => {

    return (
        <div className={style.successAlert} {...props}>
            <CheckIcon/>
            <Typography variant={"body1"} className={style.textAlert}>{children}</Typography>
        </div>
    );
};

export default SuccessAlert;
