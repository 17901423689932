import { useEffect, useState} from "react";

export const useCountdown = ( initialSeconds: number) => {
    const [seconds, setSeconds] = useState(initialSeconds);
    const [timeout, _setTimeout] = useState<ReturnType<typeof setTimeout>>();
    const [started, setStarted] = useState(false);

    const countDown = () => {
        setSeconds((prev) => prev - 1);
    };



    const reset = () => {
        setSeconds(initialSeconds);
        clearTimeout(timeout);
        setStarted(true)
    };

    const startCountdown = (initSec:number) => {
        setSeconds(initSec)
        setStarted(true)
    }



    useEffect(() => {
        if (started) {
            if (seconds === 0) return setStarted(false);
            const timer = setTimeout(() => {
                countDown();
            }, 1000);
            _setTimeout(timer);
        }
    }, [seconds, started]);


    return {seconds, reset, startCountdown};
};
