import React, {CSSProperties, FunctionComponent} from 'react';
import {Table, TableBody, TableCell, TableHead, TableRow,} from "@sber-friend/flamingo-core";
import style from "./AppTable.module.scss";

interface OwnProps {
    column: Object,
    rows: Array<Object>,
    align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
    size?: 'small' | 'medium';
    stickyHeader?: boolean;
    className?: string;
    style?: CSSProperties;
    onClickRow?: (el: Object, event: Object) => void;
}

type Props = OwnProps;

const AppTable: FunctionComponent<Props> = ({column, align, rows, onClickRow, ...props}) => {

    const handleClickRow = (row: Object) => (event: Object) => {
        if (onClickRow)
            onClickRow(row, event)
    }

    return (
        <Table {...props} aria-label="a dense table">
            <TableHead className={style.tableHead}>
                <TableRow>
                    {
                        Object.values(column).map((e, i) => i === 0 ? <TableCell key={e + i}>{e}</TableCell>
                            : <TableCell align={align} key={e + i}>{e}</TableCell>)
                    }
                </TableRow>
            </TableHead>
            <TableBody className={style.tableBody}>
                {rows.map((row, i) => (
                    <TableRow key={"row" + i} onClick={handleClickRow(row)} className={onClickRow? style.clickableRow : ""}>
                        {Object.keys(column).map((e, index) => {
                            for (const property in row) {
                                if (property === e) {
                                    if (index === 0) {
                                        return <TableCell style={{maxWidth:200}} key={"cell" + i + index} component="th"
                                                          scope="row"><>{row[property as keyof typeof row]}</>
                                        </TableCell>
                                    }
                                    return <TableCell key={"cell" + i + index}
                                                      align={align}><>{row[property as keyof typeof row]}</>
                                    </TableCell>
                                }
                            }
                            return null
                        })}
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

export default AppTable;
