import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {Provider} from "react-redux";
import {store} from "./store";
import {BrowserRouter} from "react-router-dom";
import "./assets/global.scss";
import { ThemeProvider } from '@sber-friend/flamingo-core';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

if (process.env.NODE_ENV === 'development') {
    const { worker } = require('./mocks/browser')
    worker.start()
}

root.render(
    <Provider store={store}>
        <BrowserRouter>
            <ThemeProvider mode="light"  locale="ru-RU">
                <App/>
            </ThemeProvider>
        </BrowserRouter>
    </Provider>
);

console.debug("Version:", process.env.APP_VERSION);
console.debug("Build:", process.env.GIT_SHA);

