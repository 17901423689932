import React, {FunctionComponent, ReactNode,} from 'react';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import style from "./SidePanel.module.scss";
import {Divider, IconButton, Typography} from "@sber-friend/flamingo-core";
import {useWindowSize} from "usehooks-ts";
import {CloseIcon} from "@sber-friend/flamingo-icons";


interface OwnProps {
    open: boolean;
    onOpen: () => void;
    onClose: () => void;
    header?: string | ReactNode;
    children?: JSX.Element | JSX.Element[] | ReactNode;
}


type Props = OwnProps;

const SidePanel: FunctionComponent<Props> = ({open, onOpen, onClose, header, children}) => {

    const {width} = useWindowSize();


    return (
        <SwipeableDrawer
            disableSwipeToOpen
            anchor={width > 650 ? "right" : "bottom"}
            open={open}
            onClose={onClose}
            onOpen={onOpen}
        >
            <div className={style.sidePanel}>
                <div className={style.header}>
                    <span className={style.thing}/>
                    <Typography variant={"h6"}>{header}</Typography>
                    <IconButton className={style.closeButton} onClick={onClose}><CloseIcon/></IconButton>
                    <Divider className={style.divider}/>
                </div>
                {children}
            </div>
        </SwipeableDrawer>
    );
};

export default SidePanel;
