import React, {FunctionComponent, KeyboardEvent, useEffect, useState} from 'react';
import {
    Alert,
    Button, CircularLoader,
    TextField,
    Typography
} from "@sber-friend/flamingo-core";
import style from "./Delegation.module.scss";
import AppTable from "../../../components/AppTable/AppTable";
import SidePanel from "../../../components/SidePanel/SidePanel";
import SuccessAlert from "../../../components/SuccessAlert/SuccessAlert";
import {useDebounce, useWindowSize} from "usehooks-ts";
import {
    DeleteVipPayloadReq,
    IDevice, useAddVipDeviceMutation,
    useDeleteVipDeviceMutation,
    useGetVipMembersQuery,
    useLazyGetVipDevicesQuery
} from "../../../api/devices";
import {formatDateTime} from "../../../helpers";
import ConfirmModal from "../ConfirmModal/ConfirmModal";
import {ArrowLeftIcon, PlusIcon, SearchIcon, TrashIcon} from '@sber-friend/flamingo-icons';
import Snackbar from "@sber-friend/flamingo-core/Snackbar";


interface OwnProps {
}

interface IDeleteDevice {
    devices: Array<{
        device_id: string
        platform: string
        name: string
    }>,
    pendingDevices: Array<{
        device_id: string
        name: string
    }>
}

interface listEl extends IDevice {
    statusStr: string
    actions: JSX.Element
}

interface User {
    login: string
    visibleName: string
}

type Props = OwnProps;


const Delegation: FunctionComponent<Props> = (props) => {


    const column = {
        visibleName: "ФИО"
    }

    const columnUserDevices = {
        name: "Устройство",
        last_active_time: "Последний вход",
        statusStr: "Статус",
        actions: "",
    }


    const {width} = useWindowSize();


    const [activeUser, setActiveUser] = useState<undefined | User>()

    const [confirmModal, setConfirmModal] = useState(false);
    const [deleteDevice, setDeleteDevice] = useState<IDeleteDevice>({
        pendingDevices: [
            {
                device_id: "",
                name: ""
            }
        ], devices: [{
            device_id: "",
            platform: "",
            name: "",
        }]
    });

    const [openSidePanel, setOpenSidePanel] = useState(false);

    const [addDeviceCode, setAddDeviceCode] = useState("");


    const [searchVal, setSearchVal] = useState('')
    const [fetchVal, setFetchVal] = useState('')
    const debouncedValue = useDebounce<string>(searchVal, 1500)


    const {data: vipMembers, isFetching: isFetchingVipMembers, error: errorVipMembers} = useGetVipMembersQuery(fetchVal)
    const [getVipDevices, {
        isFetching: isFetchingVipDevices,
        data: vipDevices,
        error: vipDevicesError
    }] = useLazyGetVipDevicesQuery()

    const [deleteVipDevices, deleteVipDevicesData] = useDeleteVipDeviceMutation()
    const [addVipDevice, addVipDeviceData] = useAddVipDeviceMutation()

    const [deleteError, setDeleteError] = useState('');


    const handleOpenSidePanel = () => {
        setOpenSidePanel(true)
    }

    const handleCloseSidePanel = () => {
        addVipDeviceData.reset();
        setOpenSidePanel(false)
    }

    const handleBack = () => {
        setActiveUser(undefined)
    }

    const chooseUser = (row: any) => {
        setActiveUser(row)
    }

    const handleAddDevice = async () => {
        if (addDeviceCode.trim() && activeUser?.login) {
            await addVipDevice({
                login: activeUser.login,
                time_code: addDeviceCode.trim()
            })
            setAddDeviceCode("")
        }

    }

    const handleClickOnDeleteAll = () => {
        const payload: IDeleteDevice = {
            devices: vipDevices ? vipDevices.result.sessions.map(e => ({
                device_id: e.device_id,
                platform: e.platform,
                name: e.name
            })) : [],
            pendingDevices: vipDevices ? vipDevices.result.pendingDevices.map(e => ({
                device_id: e.device_id,
                name: e.name
            })) : []
        }
        setDeleteDevice(payload)
        setConfirmModal(true)
    }
    const handleClickOnDeleteOne = (e: listEl) => () => {
        const payload: IDeleteDevice = {
            devices: !e.status ? [{
                device_id: e.device_id,
                platform: e.platform,
                name: e.name
            }] : [],
            pendingDevices: e.status ? [{
                device_id: e.device_id,
                name: e.name
            }] : []
        }
        setDeleteDevice(payload)
        setConfirmModal(true)
    }

    const handleDeleteDevices = async () => {
        const payload: DeleteVipPayloadReq = {
            login: activeUser?.login ? activeUser?.login : "",
            pendingDevices: deleteDevice.pendingDevices.map(e => e.device_id),
            devices: deleteDevice.devices.map(e => ({device_id: e.device_id, platform: e.platform}))
        }
        await deleteVipDevices(payload)
    }

    const onCloseModal = () => {
        if (!deleteVipDevicesData.isLoading) {
            setConfirmModal(false)
        }
    }

    const handleSearch = (e: KeyboardEvent) => {
        if (e.key === "Enter") {
            setFetchVal(searchVal)
        }
    }

    useEffect(() => {
        if (deleteVipDevicesData?.error?.message) {
            setDeleteError(deleteVipDevicesData.error.message)
        }
    }, [deleteVipDevicesData?.error])

    useEffect(() => {
        setFetchVal(debouncedValue)
    }, [debouncedValue])

    useEffect(() => {
        if (activeUser) {
            getVipDevices(activeUser.login)
        }
    }, [activeUser, getVipDevices])

    const list: Array<listEl> = vipDevices ? [...vipDevices.result.pendingDevices.map(e => {
        let newEl = {
            ...e,
            last_active_time: formatDateTime(e.last_active_time),
            statusStr: !e.status ? "Зарегистрировано" : "В процессе",
            actions: <Button className={style.textSecondary} onClick={handleClickOnDeleteOne(e as listEl)}
                             variant={"text"} startIcon={<TrashIcon/>}>
                <Typography variant={"body2"}>Удалить устройство</Typography>
            </Button>
        }
        return newEl
    }), ...vipDevices.result.sessions.map(e => {
        let newEl = {
            ...e,
            last_active_time: formatDateTime(e.last_active_time),
            statusStr: !e.status ? "Зарегистрировано" : "В процессе",
            actions: <Button className={style.textSecondary} onClick={handleClickOnDeleteOne(e as listEl)}
                             variant={"text"} startIcon={<TrashIcon/>}>
                <Typography variant={"body2"}>Удалить устройство</Typography>
            </Button>
        }
        return newEl
    })] : []

    return activeUser ? <>
            <Button startIcon={<ArrowLeftIcon/>} variant={"text"} className={style.backButton}
                    onClick={handleBack}><Typography>Назад</Typography></Button>

            {
                isFetchingVipDevices ? <div style={{height: 400}} className={"center"}>
                        <CircularLoader size={"large"}/>
                    </div>
                    : (vipDevicesError?.message ? <div className={style.errorWrap}>
                            <Alert severity={"error"}>{vipDevicesError?.message}</Alert>
                            <Button color="primary"
                                    style={{marginTop: 20}}
                                    onClick={() => getVipDevices(activeUser?.login)}
                            >
                                Попробовать снова
                            </Button>
                        </div>
                        : <>
                            <div className={style.headDelegate}>
                                <Typography variant={"h5"}>{activeUser.visibleName}</Typography>
                                <div className={style.tableActions}>
                                    <Button variant={"text"}
                                            startIcon={<TrashIcon/>}
                                            className={style.deleteAllButton}
                                            onClick={handleClickOnDeleteAll}
                                    >
                                        <Typography variant={"h6"}>Удалить все</Typography>
                                    </Button>
                                    <Button startIcon={<PlusIcon/>} onClick={handleOpenSidePanel}
                                            color={"primary"}>Зарегистрировать</Button>
                                </div>
                            </div>
                            {width > 767 ? <AppTable column={columnUserDevices} rows={list} align={"right"}/>
                                : <div className={style.mobileTable}>
                                    {list.map(e => <div className={style.mobileTableItem}>
                                        <div className={style.head}>
                                            {e.name}
                                            <Button variant={"text"} className={style.textSecondary}
                                                    onClick={handleClickOnDeleteOne(e)}
                                                    startIcon={<TrashIcon/>}><Typography
                                                variant={"body2"}>Удалить</Typography></Button>
                                        </div>
                                        <Typography variant={"body2"}>
                                            <span className={style.textSecondary}>Последний вход: </span>
                                            {e.last_active_time}
                                        </Typography>
                                    </div>)}
                                </div>}
                        </>)
            }
            <SidePanel open={openSidePanel} onOpen={handleOpenSidePanel} onClose={handleCloseSidePanel}
                       header={"Добавить устройство"}>

                {
                    addVipDeviceData.isSuccess ?
                        <SuccessAlert style={{marginBottom: 16}}>Устройство подтверждено. Передайте его
                            пользователю</SuccessAlert> : <>
                            <Typography>Скопируйте код регистрации из приложения OTP</Typography>
                            <div className={style.actions}>
                                <TextField placeholder={"Код регистрации"}
                                           value={addDeviceCode}
                                           onChange={(e) =>{
                                               let newVal = e.target.value.replace(/[^a-zA-Z0-9]/g,'').replace(/[0]/g, '').toUpperCase()
                                               if (newVal.length < 7)
                                                   setAddDeviceCode(newVal)
                                           }}
                                           disabled={addVipDeviceData.isLoading}
                                           helperText={addVipDeviceData?.error?.message}
                                           error={!!addVipDeviceData?.error?.message}
                                           onKeyDown={(e) => {
                                               if (e.key === "Enter" && addDeviceCode.length === 6) {
                                                   handleAddDevice()
                                               }
                                           }}
                                           customCounter={`${addDeviceCode.length}/6`}
                                />
                                <Button color={"primary"}
                                        loading={addVipDeviceData.isLoading}
                                        onClick={handleAddDevice}
                                        disabled={addDeviceCode.length !== 6}
                                >
                                    Добавить
                                </Button>
                            </div>
                        </>

                }
                <Typography className={style.subText} variant={"body2"}>При входе в приложение OTP пользователь должен
                    пройти аутентификацию с помощью
                    Сбер ID. Если он это не сделает, то приложение не активируется.</Typography>
            </SidePanel>
            <ConfirmModal openModal={confirmModal}
                          onConfirm={handleDeleteDevices}
                          deleteDeviceName={[...deleteDevice.pendingDevices.map(e => e.name), ...deleteDevice.devices.map(e => e.name)]}
                          onCloseModal={onCloseModal}
            />
            <Snackbar
                placement={"top"}
                variant="error"
                open={!!deleteError}
                iconButtonClose
                transitionDuration={300}
                TransitionProps={{}}
                autoHideDuration={7000}
                onClose={() => {
                    setDeleteError('')
                }}
                message={deleteError}
            />
        </>
        : <>
            <div className={style.searchWrap}>
                <TextField fullWidth
                           placeholder={"Поиск по ФИО"}
                           endIcon={<SearchIcon/>}
                           value={searchVal}
                           loading={isFetchingVipMembers}
                           disabled={isFetchingVipMembers}
                           onChange={(e) => setSearchVal(e.target.value)}
                           onKeyDown={handleSearch}
                />
            </div>
            <AppTable column={column} rows={vipMembers ? vipMembers.result : []} onClickRow={chooseUser}/>
            {
                !!errorVipMembers && <Alert severity="error" mt={2}>{errorVipMembers.message}</Alert>
            }
        </>
};

export default Delegation;
