export const routesPath = {
    main: "/",
    devices: "/devices",
    instructions: "/instruction",
    questions: "/questions",
    sudirAuth: "/sudir/sessions",
    serverError: "/server-error",
    sudirBlocked:"/sudir-blocked",
    qrCodeDownload:"/qr-code-download"
}
