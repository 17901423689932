import React, {FunctionComponent, PropsWithChildren} from 'react';
import Header from "../Header/Header";
import style from "./LayoutApp.module.scss";
import {Divider} from "@sber-friend/flamingo-core";
import LogoGray from "../../assets/img/icons/logoGray.svg";

interface OwnProps {
}

type Props = OwnProps;

const LayoutApp: FunctionComponent<Props> = ({children}: PropsWithChildren<Props>) => {

    return (
        <div className={style.wrap}>
            <Header/>
            <div className={style.content + (" container")}>
                {children}
            </div>
            <Divider/>
            <footer className={style.footer + (" container")}>
                <img src={LogoGray} alt="SberLogoGray"/>
                <span>Сделано в SberUser</span>
            </footer>
        </div>
    );
};

export default LayoutApp;
