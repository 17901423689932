import {combineReducers, configureStore} from '@reduxjs/toolkit'
import {authApi} from "../api/auth/authApi";
import authReducer from "../api/auth/authSlice"
import devicesReducer from "../api/devices/devicesSlice"
import {devicesApi} from "../api/devices";
import {mainPageApi} from "../api/main";


const rootReducer = combineReducers({
    auth: authReducer,
    devices: devicesReducer,
    [authApi.reducerPath]: authApi.reducer,
    [devicesApi.reducerPath]: devicesApi.reducer,
    [mainPageApi.reducerPath]: mainPageApi.reducer,
})

export const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat(authApi.middleware).concat(devicesApi.middleware).concat(mainPageApi.middleware)
})

export type RootState = ReturnType<typeof rootReducer>
export type AppDispatch = typeof store.dispatch
