import React, {FunctionComponent, useEffect, useState, KeyboardEvent} from 'react';
import style from "./Login.module.scss";
import {
    Button,

    IconButton,
    TextField,
    Typography
} from "@sber-friend/flamingo-core";
import {useGetPublicKeyMutation, useLoginMutation} from "../../../api/auth/authApi";
import {encryptPassword} from "../../../api/jwt";
import {sendEvent} from '@sbol/clickstream-agent/sdk';
import {getCookie, setCookie} from "../../../helpers";
import { EyeIcon, EyeOffIcon} from "@sber-friend/flamingo-icons";
import AuthErrorScreen from "../../../components/AuthErrorScreen/AuthErrorScreen";

interface OwnProps {
}

type Props = OwnProps;

const Login: FunctionComponent<Props> = (props) => {

    const AuthError = [49,53,54,55,56,57,58,63]

    const [loginFetch, {error: loginError, reset:resetLoginFetch}] = useLoginMutation()
    const [getPublicKey, {error: publicKeyError, reset: resetGetPublicKey}] = useGetPublicKeyMutation()

    const [errorMessage, setErrorMessage] = useState('');
    const [errorCode, setErrorCode] = useState<number>(0);

    const [loading, setLoading] = useState(false)
    const [login, setLogin] = useState('')
    const [password, setPassword] = useState('')
    const [showPassword, setShowPassword] = useState(false)
    const [capsLockIsActive, setCapsLockIsActive] = useState("");


    const handleClickLogIn = async () => {
        setLoading(true)
        await getPublicKey({login}).then(async (r) => {
            if ("data" in r) {
                const key = r.data.result
                const encryptedPassword = await encryptPassword(key, password);
                if (encryptedPassword) {
                    await loginFetch({login, password: encryptedPassword})
                } else {
                    console.debug("encrypt password failed")
                }
            }
            setLoading(false)
        })

    }

    const onKeyDownPasswordField = (e: KeyboardEvent<HTMLImageElement>) => {
        if (e.key === "Enter") {
            handleClickLogIn()
        }
        if (e.getModifierState("CapsLock") && !capsLockIsActive){
            setCapsLockIsActive("* Включён Caps lock")
        }
    }


    const closeError = () => {
        setErrorMessage("")
        setErrorCode(0)
        setLogin("")
        setPassword("")
        setShowPassword(false)
        resetGetPublicKey()
        resetLoginFetch()
    }

    useEffect(() => {
        if (publicKeyError?.message) {
            setErrorCode(Number(publicKeyError.code))
            setErrorMessage(publicKeyError.message)
            // отправка метрик click stream
            sendEvent(
                {
                    eventCategory: 'Вход в Устройства',
                    eventAction: 'Логин и пароль/ошибка при входе в Устройства',
                    value: `get_public ${publicKeyError?.code}: ${publicKeyError?.message}`,
                }
            )
            //
        }
        if (loginError?.message) {
            setErrorCode(Number(loginError.code))
            setErrorMessage(loginError.message)
            // отправка метрик click stream
            sendEvent(
                {
                    eventCategory: 'Вход в Устройства',
                    eventAction: 'Логин и пароль/ошибка при входе в Устройства',
                    value: `auth_int_portal ${loginError?.code}: ${loginError?.message}`,
                }
            )
            //
        }
    }, [loginError, publicKeyError])

    useEffect(()=>{
        setCookie('logInTime',"")
    },[])

    return (
        <div className={style.wrap}>
            {
                AuthError.includes(errorCode) ? <AuthErrorScreen code={errorCode} closeError={closeError}/>
                    : <div className={style.content}>
                        <Typography variant={"h5"}>Авторизация</Typography>
                        <Typography variant={"body2"} style={{marginBottom: 30, marginTop: 10}}>Авторизуйтесь для
                            отображения списка устройств.
                        </Typography>
                        <TextField placeholder={"18071500"}
                                   label={"Логин Сигма"}
                                   disabled={loading}
                                   error={!!errorMessage}
                                   fullWidth
                                   value={login}
                                   onChange={(e) => {
                                       if (!getCookie('logInTime')){
                                           setCookie('logInTime',Date.now().toString())
                                       }
                                       setLogin(e.target.value)
                                   }}
                                   onFocusCapture={() => {
                                       setCapsLockIsActive("")
                                       setErrorMessage('')
                                   }}
                        />
                        <TextField type={showPassword ? "default" : "password"}
                                   disabled={loading}
                                   label={"Пароль Сигма"}
                                   error={!!errorMessage}
                                   helperText={errorMessage || capsLockIsActive}
                                   fullWidth
                                   value={password}
                                   onFocusCapture={(e) => {
                                       setErrorMessage('')
                                   }}
                                   onKeyDown={onKeyDownPasswordField}
                                   onKeyUp={(e)=>{
                                       if (!e.getModifierState("CapsLock") && capsLockIsActive){
                                           setCapsLockIsActive("")
                                       }
                                   }}
                                   onChange={(e) => {
                                       setPassword(e.target.value)
                                   }}
                                   endIcon={showPassword ? <IconButton
                                           size={"small"}
                                           onClick={() => setShowPassword(false)}
                                       >
                                           <EyeIcon color="primary"/>
                                       </IconButton>
                                       : <IconButton
                                           size={"small"}
                                           onClick={() => setShowPassword(true)}
                                       >
                                           <EyeOffIcon color="primary"/>
                                       </IconButton>
                                   }
                        />
                        <div className={style.buttonsWrap}>
                            <Button color={"primary"} disabled={!password || !login} onClick={handleClickLogIn}
                                    loading={loading}>Войти</Button>
                        </div>
                    </div>
            }
        </div>
    );
};

export default Login;
