import React, {FunctionComponent} from 'react';
import style from "./Error503Page.module.scss";
import { Typography} from "@sber-friend/flamingo-core";
import Error503 from "../../assets/img/Commons/Error503.svg"


interface OwnProps {
}

type Props = OwnProps;

const Error503Page: FunctionComponent<Props> = (props) => {


    return (
        <div className={style.wrap}>
            <div className={style.content}>
                <img src={Error503} alt="Error503"/>
                <div>
                    <Typography variant={"h5"}>Технические неполадки</Typography>
                    <Typography variant={"body1"} color={"textSecondary"}>
                        Это временно, скоро всё наладим
                    </Typography>
                </div>
            </div>
        </div>
    );
};

export default Error503Page;
