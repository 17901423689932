import React, {FunctionComponent} from 'react';
import style from "./NotFoundPage.module.scss";
import {Button, Typography} from "@sber-friend/flamingo-core";
import {routesPath} from "../../routes/const";
import {useNavigate} from "react-router-dom";
import NotFoundImage from "../../assets/img/Commons/PageNotFound.svg"


interface OwnProps {
}

type Props = OwnProps;

const NotFoundPage: FunctionComponent<Props> = (props) => {

    const navigate = useNavigate();

    const goToMain = () => {
        navigate(routesPath.main)
    }

    return (
        <div className={style.wrap}>
            <div className={style.content}>
                <img src={NotFoundImage} alt="notFound"/>
                <div>
                    <Typography variant={"h5"}>Такой страницы нет</Typography>
                    <Typography variant={"body1"} color={"textSecondary"}>
                        Возможно, в ссылку закралась ошибка.
                        <br/>Попробуйте поискать на главной или позвоните
                        <br/>в поддержку:
                        <br/><a className={"green-link"} href="tel:88007073277">8 800 707-32-77</a> (внешний)
                        <br/><a className={"green-link"} href="tel:855110911">8-551-10911</a> (внутренний)
                    </Typography>
                    <Button onClick={goToMain}>На главную</Button>
                </div>
            </div>
        </div>
    );
};

export default NotFoundPage;
