import React, {FunctionComponent} from 'react';
import style from "./SudirErrorPage.module.scss";
import {Button, Typography} from "@sber-friend/flamingo-core";
import {routesPath} from "../../routes/const";
import {useNavigate} from "react-router-dom";
import SudirLock from "../../assets/img/Commons/SudirLock.svg"


interface OwnProps {
}

type Props = OwnProps;

const SudirErrorPage: FunctionComponent<Props> = (props) => {

    const navigate = useNavigate();

    const goToDevices = () => {
        navigate(routesPath.devices)
    }

    return (
        <div className={style.wrap}>
            <div className={style.content}>
                <img src={SudirLock} alt="SudirLock"/>
                <div>
                    <Typography variant={"h5"}>Не можем пустить в ОТР</Typography>
                    <Typography variant={"body1"} color={"textSecondary"}>
                        Возможно, учётная запись заблокирована СУДИР.
                        <br/>Попробуйте ещё раз или позвоните в поддержку:
                        <br/><a className={"green-link"} href="tel:88007073277">8 800 707-32-77</a> (внешний)
                        <br/><a className={"green-link"} href="tel:855110911">8-551-10911</a> (внутренний)
                    </Typography>
                    <Button onClick={goToDevices}>Войти</Button>
                </div>
            </div>
        </div>
    );
};

export default SudirErrorPage;
